import React, {useState} from 'react';

const ProductDocuments = ({productName, lockLabel, brochureLabel, brochure, dataSheetLabel, dataSheet, cls = ''}) => {
  return (
      <>
        <span className={cls.length > 0 ? 'body-style-label '+cls : 'body-style-label'}>{lockLabel}</span>
        <div className={cls.length > 0 ? 'body-style-brochure '+cls : 'body-style-brochure'}>
          <div>
            <a href={brochure} target={'_blank'} className={productName+'-brochure-button'}>
              {brochureLabel}
            </a>
          </div>
          <div>
            &nbsp;|&nbsp;
          </div>
          <div className={'data-sheets'}>
            <a
                href={dataSheet}
                target={'_blank'}
                className={'datasheet-button'}
                data-product={productName}
            >
              {dataSheetLabel}
            </a>
          </div>
        </div>
      </>
  )
}
export default ProductDocuments;